/* eslint-disable react/jsx-no-target-blank */
import GitHubIcon from "@mui/icons-material/GitHub";
import MailIcon from "@mui/icons-material/Mail";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import "./Footer.css";

const useStyles = makeStyles((theme) => ({
  footerCard: theme.footerCard,
  footerCardContent: theme.footerCardContent,
  footerKeywords: theme.footerKeywords,
}));

const Footer = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [pageVisit, setPageVisit] = useState(new Map());
  const [showStats, setShowStats] = useState(false);

  useEffect(() => {
    const uniqueUserCount = async () => {
      fetch(
        `https://perpverse-default-rtdb.asia-southeast1.firebasedatabase.app/telemetry-app-visit.json`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setUsers(Object.keys(res).map((appVisit) => res[appVisit].sessionId));
        });
    };
    uniqueUserCount();
  }, []);

  useEffect(() => {
    const pageVisitCount = async () => {
      fetch(
        `https://perpverse-default-rtdb.asia-southeast1.firebasedatabase.app/telemetry-page-visit.json`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          const dataMap = new Map();
          Object.keys(res).forEach((item) => {
            const sessionId = res[item].sessionId;
            if (dataMap.has(sessionId)) {
              dataMap.set(
                sessionId,
                `${dataMap.get(sessionId)} > ${res[item].pageName}`
              );
            } else {
              dataMap.set(sessionId, res[item].pageName);
            }
          });

          setPageVisit(dataMap);
        });
    };
    pageVisitCount();
  }, []);

  return (
    <div className={`container-fliud mt-5 ps-0 pe-0 ${classes.footerCard}`}>
      <div className="container">
        <div className={`row mt-4 ${classes.footerCardContent}`}>
          <div className="col-md-6">
            <div className="col-md-12 link-container">
              <a
                href="https://vigneshramamoorthy.prepverse.net"
                target="_blank"
                className="link"
              >
                <PermIdentityIcon />
                <span className="link-text">Vignesh Ramamoorthy</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
            <div className="col-md-12 link-container">
              <a
                href="https://github.com/VigneshRamamoorthy1992"
                target="_blank"
                className="link"
              >
                <GitHubIcon />
                <span className="link-text">Github</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12 link-container">Looking to reachout?</div>
            <div className="col-md-12 link-container">
              <a
                href="https://twitter.com/prep_verse"
                target="_blank"
                className="link"
              >
                <TwitterIcon />
                <span className="link-text">Twitter</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
            <div className="col-md-12 link-container">
              <a
                href="mailto:contact.prepverse@gmail.com"
                target="_blank"
                className="link"
              >
                <MailIcon />
                <span className="link-text">Mail</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
            <div className="col-md-12 link-container">
              <a
                href="https://join.slack.com/t/prepverse/shared_invite/zt-12cmeti9i-u2L2J6qE_O3~in4O84pbaA"
                className="link"
                target="_blank"
              >
                <MessageIcon />
                <span className="link-text">Slack</span>
                <OpenInNewIcon className="icon" />
              </a>
            </div>
          </div>
          {showStats && (
            <div style={{ padding: "10px" }}>
              <div>Site Visits : {users.length}</div>
              <div>
                Unique Site Visits :{" "}
                {
                  users.filter((value, index, array) => {
                    return array.indexOf(value) === index;
                  }).length
                }
              </div>
              <Table aria-label="simple table">
                <TableBody>
                  {[...pageVisit.keys()].map((key, index) => {
                    return (
                      <TableRow
                        key={key + index.toString()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {key}
                        </TableCell>
                        <TableCell align="left">{pageVisit.get(key)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          <Divider className="mt-4" />
        </div>
        <Button
          onClick={() => {
            const passkey = prompt("enter creds: ");
            if (passkey === "keerthi") {
              setShowStats(!showStats);
            }
          }}
        >
          stats
        </Button>
      </div>
    </div>
  );
};

export default Footer;
