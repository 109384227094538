import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Loader from "../components/loader/Loader";

const Home = lazy(() => import("../features/home/Home"));
const About = lazy(() => import("../features/about/About"));
const ProblemDetails = lazy(() =>
  import("../features/problem-details/ProblemDetails")
);
const DsaVideos = lazy(() => import("../features/dsa-videos/DsaVideos"));
const ProblemList = lazy(() => import("../features/problem-list/ProblemList"));
const Header = lazy(() => import("../components/header/Header"));

const AppRoutes = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Header
          currentTheme={(theme) => {
            props.currentTheme(theme);
          }}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/problemlist/:type/:catagory"
            element={<ProblemList />}
          />
          <Route
            path="/problemlist/:type/:catagory/:problem"
            element={<ProblemDetails />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/videos" element={<DsaVideos />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
